<script setup lang="ts">
const nav = ref(true)
</script>

<template lang="pug">
div
  NavVersion
  NavTop(v-model="nav")
  NavLeft(v-model="nav")
</template>
