<script setup lang="ts">
import moment from 'moment'

const openFeature = useOpenFeature()

const tick = ref(0)
const ttl = computed(() => {
  tick.value
  const dueAt = moment.unix(openFeature.getNumber('countdown_timer'))
  // const dueAt = moment().add(48, 'hours').add(-55 * tick.value, 'seconds')
  return moment.duration(
    dueAt.diff(moment())
  )
})

onMounted(() => {
  const interval = setInterval(() => {
    tick.value++
  }, 1000)
  onBeforeUnmount(() => {
    clearInterval(interval)
  })
})

const color = computed(() => {
  if (ttl.value.asSeconds() <= 0) {
    return 'error'
  }

  if (ttl.value.asSeconds() < moment.duration(8, 'hours').asSeconds()) {
    return 'warning'
  }

  return 'success'
})

function lerp(a: number, b: number, t: number): number {
  return a + (b - a) * t;
}
function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}
function floorOrCeil(value: number): number {
  return value >= 0 ? Math.floor(value) : Math.ceil(value);
}

const icon = computed(() => {
  const words = ['twelve', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven']
  const hoursRemaining = Math.ceil(ttl.value.asHours())
  const t = clamp(hoursRemaining / 48, 0, 1)
  let i = Math.floor(lerp(1, 11, t))
  if (hoursRemaining <= 0) i = 0
  const word = words[i]
  return `mdi-clock-time-${word}-outline`
})

const countdown = computed(() => {
  const d = ttl.value
  const [h, m, s] = [
    floorOrCeil(d.asHours()),
    d.minutes(),
    d.seconds()
  ]
  return `${h}h : ${m}m : ${s}s`
})
</script>

<template lang="pug">
v-list-item(
  :color="color"
  :active="true"
  title="Submission Deadline"
  :subtitle="countdown"
)
  template(v-slot:prepend)
    v-icon.animate__animated.animate__heartBeat.animate__infinite(:icon="icon")
</template>
