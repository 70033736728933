<script setup lang="ts">
const { $um } = useNuxtApp()
const { impersonate } = useSession()
const { isAuthenticated, user, impersonating, loading } = storeToRefs(useSession())

const props = defineProps<{
  modelValue: boolean
}>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const links = ref([
  {
    icon: 'mdi-facebook',
    href: 'https://www.facebook.com/SATOFilmChallenge',
  },
  {
    icon: 'mdi-instagram',
    href: 'https://www.instagram.com/sato48_filmchallenge/',
  },
  {
    icon: 'mdi-youtube',
    href: 'https://www.youtube.com/channel/UCuFIN8GroLc1_l37VB-Sxuw',
  },
  {
    icon: 'mdi-vimeo',
    href: 'https://vimeo.com/user39636028',
  },
  {
    icon: 'mdi-twitter',
    href: 'https://twitter.com/SATO48Film',
  },
  {
    icon: 'mdi-slack',
    href: 'https://join.slack.com/t/sato48/shared_invite/zt-opau8j4k-SvNl8K4y_eeuBtaZlRIheA',
  },
  {
    icon: 'mdi-patreon',
    href: 'https://www.patreon.com/sato48',
  },
  {
    icon: 'mdi-tshirt-v',
    href: 'https://sato48.threadless.com',
  },
])

const login = () => {
  $um.signinRedirect()
}

const logout = () => {
  localStorage.clear()
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const c = useCookie(cookies[i].split('=')[0])
    c.value = null
  }
  window.location.href = '/'
}

const toggleNav = () => {
  emit('update:modelValue', !props.modelValue)
}
</script>

<template lang="pug">
div
  v-app-bar(app)
    v-app-bar-nav-icon(v-if="!$vuetify.display.smAndUp" @click="toggleNav")

    template(v-if="$vuetify.display.smAndUp")
      v-img.mx-3(
        src="https://img-cc.sato48.com/sato48/rs:fit:800/plain/s3://sato48-assets/sato48-logo-2024.png",
        max-height="64"
        max-width="356"
        position="left center"
      )
      v-divider.mr-3(vertical)
      v-btn(v-for="link in links" v-bind="link" size="small" variant="text" target="_blank")
    template(v-else)
      v-img(
        src="https://img-cc.sato48.com/sato48/rs:fit:800/plain/s3://sato48-assets/sato48-logo-2024.png",
        position="center center"
      )


    template(v-slot:append)
      v-chip.mr-3(v-if="impersonating" color="error" prepend-icon="mdi-incognito" closable @click:close="impersonate()") Masquerading
      v-divider.mr-4(vertical)

      template(v-if="loading")
        v-skeleton-loader(:loading="loading" type="avatar")
        v-btn(icon="mdi-logout" color="primary" disabled)

      template(v-else-if="!isAuthenticated")
        v-btn(append-icon="mdi-login" text="Login" @click="login" color="primary")

      template(v-else)
        v-app-bar-title.mr-4(v-if="$vuetify.display.smAndUp") {{ user.name }}
        v-avatar.mr-3(color="grey darken-1 shrink" size="48")
          v-img(v-if="isAuthenticated && user" :src="user.picture")
        v-tooltip(text="Logout" location="bottom")
          template(v-slot:activator="{ props }")
            v-btn(v-bind="props" icon="mdi-logout" color="primary" @click="logout" :disabled="!!impersonating")
</template>
