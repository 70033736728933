<template lang="pug">
v-app
  VitePwaManifest
  NavGlobal(v-if="!openFeature.getBool('maintenance_mode', false)")
  slot
  NotifySnackbar
</template>

<script setup lang="ts">
useHead({
  titleTemplate: (title) => {
    return title
      ? `${title} - SATO48 Festival Manager`
      : 'SATO48 Festival Manager'
  },
})

const openFeature = useOpenFeature()
</script>

<style>
p {
  margin-bottom: 10px;
}
</style>
