<script setup lang="ts">
const config = useRuntimeConfig()
const openFeature = useOpenFeature()

const hide = ref(false)

const onPreviousYear = () => {
  localStorage.setItem('sato48:lastYear', 'true')
  openFeature.mergeContext({
    lastYear: true
  })
}

const onCurrentYear = () => {
  localStorage.removeItem('sato48:lastYear')
  openFeature.mergeContext({
    lastYear: false
  })
}
</script>

<template lang="pug">
v-system-bar(:color="config.public.datadog.env.startsWith('prd') ? 'primary' : 'warning'" v-if="openFeature.getBool('show_revision') && !hide")
  span.ms-2.text-overline(@click="hide = true") hide
  v-spacer
  v-divider.ml-3(vertical)
  span.ms-2.text-overline rev
  span.ms-2.mono {{ config.public.datadog.version }} 
  v-divider.ml-3(vertical)
  v-menu
    template(v-slot:activator="{ props }")
      div(v-bind="props")
        span.ms-2.text-overline year
        span.ms-2.mono {{ openFeature.getNumber('current_film_festival') }}
    v-list
      v-list-item(@click="onPreviousYear")
        v-list-item-title Previous Year
      v-list-item(@click="onCurrentYear")
        v-list-item-title Current Year
  v-divider.ml-3(vertical)
  span.ms-2.text-overline env
  span.ms-2.mono {{ config.public.datadog.env }} 
</template>
